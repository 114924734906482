import {
  type MouseEvent,
  type PropsWithChildren,
  useEffect,
  useState
} from 'react'

import { differenceInMinutes, isBefore } from 'date-fns'

import { config } from '@/app/config'
import {
  addReservation,
  selectReservationDate,
  selectReservationDuration,
  selectReservationEnd,
  selectReservationPersonsCount,
  selectReservationStart,
  setReservationEnd,
  setReservationPersonsCount,
  setReservationStart
} from '@/entities/create-update-reservation'
import { MapModeEnum, setMapMode, unsetTakeTable } from '@/entities/map'
import { selectReservation } from '@/entities/reservation'
import { Reservation } from '@/features/create-update-reservation'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import {
  Button,
  type ButtonStyleProps,
  Card,
  Label,
  PersonsPicker,
  Tab,
  TabContent,
  TabsGroup,
  TabsProvider,
  Vaul,
  VaulBody,
  VaulContent,
  VaulFooter,
  VaulHeader,
  VaulTrigger
} from '@/shared/ui'
import { MobileChangeReservationTableMap } from '@/widgets/reservation/MobileChangeReservationTableMap'

import css from './MobileTransfer.module.css'

const { MIN_RESERVATION_TIME_MIN, PICKERS_INTERVAL } = config

type MobileTransferProps = ButtonStyleProps &
  PropsWithChildren & {
    reservationId: number
    className?: string
    updateReservationsList?: (
      reservationId: number,
      isDelete: boolean,
      data?: Partial<Reservation | Visit>
    ) => void
  }

export function MobileTransfer({
  children,
  reservationId,
  size,
  className,
  updateReservationsList,
  ...props
}: MobileTransferProps) {
  const [step, setStep] = useState(1)
  const [open, setOpen] = useState(false)
  const selectedStart = useAppSelector(selectReservationStart)
  const selectedEnd = useAppSelector(selectReservationEnd)
  const selectedDate = useAppSelector(selectReservationDate)
  const selectedDuration = useAppSelector(selectReservationDuration)
  const personsCount = useAppSelector(selectReservationPersonsCount)
  const reservation = useAppSelector(selectReservation)
  const dispatch = useAppDispatch()

  const isPageDuration =
    !selectedDuration ||
    (selectedDuration >= MIN_RESERVATION_TIME_MIN &&
      selectedDuration <= 4 * 60 &&
      selectedDuration % PICKERS_INTERVAL === 0)

  const toggleDialogHandler = (e: MouseEvent) => {
    e.stopPropagation()
    setOpen((prev) => !prev)
  }

  const selectStartHandler = (date: Date) => {
    if (!selectedDate) return

    selectedDate.setHours(date.getHours(), date.getMinutes())
    dispatch(setReservationStart(selectedDate))
  }

  const selectEndHandler = (date: Date) => {
    if (!selectedStart) return

    if (isBefore(date, selectedStart)) {
      dispatch(
        setReservationEnd(
          (24 - selectedStart.getHours() + date.getHours()) * 60 +
            date.getMinutes() -
            selectedStart.getMinutes()
        )
      )
    } else {
      dispatch(
        setReservationEnd(
          (date.getHours() - selectedStart.getHours()) * 60 +
            date.getMinutes() -
            selectedStart.getMinutes()
        )
      )
    }
  }

  useEffect(() => {
    if (!open) {
      setStep(1)
    }
  }, [open])

  useEffect(() => {
    if (reservation) {
      dispatch(setMapMode(MapModeEnum.one))
      dispatch(unsetTakeTable())
      dispatch(
        addReservation({
          start_date: reservation.start_date,
          end_date: reservation.end_date,
          guest: reservation.guest,
          hall_id: reservation.hall_id,
          persons_count: reservation.persons_count,
          places: [],
          duration: differenceInMinutes(
            reservation.end_date,
            reservation.start_date
          ),
          status: reservation.status
        })
      )
    }
  }, [])

  const renderContent = () => {
    switch (step) {
      case 1:
        return (
          <>
            <VaulHeader>
              <h1 className={css.title}>Изменить бронь</h1>
            </VaulHeader>
            <VaulBody className={css.body}>
              <Card data-vaul-no-drag className={css.step__picker_start}>
                <Label className={css.step__label}>Время старта</Label>
                <Reservation.MobileTimePicker
                  selectedValue={selectedStart}
                  setSelectedValue={selectStartHandler}
                />
              </Card>
              <Card className={css.step__picker_duration}>
                <TabsProvider
                  className={css.tabs}
                  defaultValue={isPageDuration ? 'duration' : 'time'}
                >
                  <TabsGroup variant="tertiary" className={css.tabs__group}>
                    <Tab
                      variant="tertiary"
                      className={css.tabs__tab}
                      value="duration"
                    >
                      Длительность
                    </Tab>
                    <Tab
                      variant="tertiary"
                      className={css.tabs__tab}
                      value="time"
                    >
                      Время завершения
                    </Tab>
                  </TabsGroup>
                  <TabContent value="duration">
                    <Reservation.MobileDurationPicker />
                  </TabContent>
                  <TabContent data-vaul-no-drag value="time">
                    <Reservation.MobileTimePicker
                      selectedValue={selectedEnd}
                      setSelectedValue={selectEndHandler}
                    />
                  </TabContent>
                </TabsProvider>
              </Card>
              <PersonsPicker
                personsCount={personsCount}
                setPersonsCount={(value) =>
                  dispatch(setReservationPersonsCount(value))
                }
              />
            </VaulBody>
            <VaulFooter>
              <Button
                variant="primary"
                size="lg"
                className={css.button}
                onClick={() => setStep(2)}
              >
                Далее
              </Button>
            </VaulFooter>
          </>
        )
      case 2:
        return (
          <MobileChangeReservationTableMap
            startDate={selectedStart}
            endDate={selectedEnd}
            personsCount={personsCount}
            places={reservation?.places ?? []}
          />
        )
    }
  }

  return (
    <Vaul open={open} onOpenChange={setOpen}>
      <VaulTrigger asChild>
        <Button
          size={size}
          className={className}
          {...props}
          onClick={toggleDialogHandler}
        >
          {children}
        </Button>
      </VaulTrigger>
      <VaulContent fullScreen>{renderContent()}</VaulContent>
    </Vaul>
  )
}
