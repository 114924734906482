/* eslint-disable import/no-duplicates */
import { useEffect, useRef, useState } from 'react'

import 'swiper/css'

import { cx } from 'class-variance-authority'
import {
  add,
  addDays,
  eachDayOfInterval,
  endOfDay,
  format,
  isEqual,
  isSameDay,
  startOfDay,
  subDays
} from 'date-fns'
import { ru } from 'date-fns/locale'
import { A11y } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { type Swiper as SwiperType } from 'swiper/types'

import {
  changeReservationsSelectedInterval,
  ReservationSortEnum,
  selectReservationsSelectedInterval,
  selectReservationsSortType
} from '@/entities/reservations'
import { DatePickerVaul } from '@/features/date-picker-vaul'
import { nowWithTimezone } from '@/shared/lib'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Button, Icon, Vaul, VaulContent, VaulTrigger } from '@/shared/ui'

import css from './DateFilter.module.css'

export function DateFilter() {
  const [vaulOpen, setVaulOpen] = useState(false)
  const swiperRef = useRef<SwiperType | null>(null)
  const selectedInterval = useAppSelector(selectReservationsSelectedInterval)
  const sortType = useAppSelector(selectReservationsSortType)
  const nowTime = nowWithTimezone()
  const dates = eachDayOfInterval({
    start:
      sortType === ReservationSortEnum.closest ? nowTime : subDays(nowTime, 6),
    end: addDays(nowTime, 30)
  }).map((day) => ({
    date: day,
    dayMonth: format(day, 'dd.LL'),
    dayWeek: format(day, 'iiiiii', { locale: ru })
  }))
  const activeIndex = dates.findIndex(
    (date) =>
      selectedInterval.start_date &&
      isSameDay(date.date, selectedInterval.start_date)
  )
  const dispatch = useAppDispatch()

  const changeDate = (date: Date, isActive: boolean) => {
    if (isActive) {
      dispatch(
        changeReservationsSelectedInterval({
          start_date: null,
          end_date: null
        })
      )
    } else {
      dispatch(
        changeReservationsSelectedInterval({
          start_date:
            sortType === ReservationSortEnum.closest &&
            isEqual(startOfDay(nowTime), date)
              ? nowTime
              : date,
          end_date: add(endOfDay(date), { hours: 5 })
        })
      )
    }
  }

  useEffect(() => {
    swiperRef.current?.slideTo(activeIndex, 0)
  }, [sortType])

  return (
    <Swiper
      onBeforeInit={(swiper) => {
        swiperRef.current = swiper
      }}
      slidesPerView="auto"
      className={css.filter}
      modules={[A11y]}
    >
      {sortType === ReservationSortEnum.default && (
        <SwiperSlide
          className={css.button__picker}
          style={{ width: 'auto', height: 'auto' }}
        >
          <Vaul open={vaulOpen} onOpenChange={setVaulOpen}>
            <VaulTrigger asChild>
              {selectedInterval.start_date ? (
                <Button
                  className={css.button}
                  contentClassName={css.button__content}
                  variant="gray"
                >
                  <p className={cx(css.button__text, css.button__text_active)}>
                    {format(selectedInterval.start_date, 'dd.LL')}
                  </p>
                  <p className={css.button__text_second}>
                    <Icon name="calendar" />
                  </p>
                </Button>
              ) : (
                <Button variant="tertiary" size="icon">
                  <Icon name="calendar" />
                </Button>
              )}
            </VaulTrigger>
            <VaulContent fullScreen>
              <DatePickerVaul onOpen={setVaulOpen} />
            </VaulContent>
          </Vaul>
        </SwiperSlide>
      )}
      {dates.map((date, idx) => (
        <SwiperSlide key={idx} style={{ width: 'auto' }}>
          <Button
            className={css.button}
            contentClassName={css.button__content}
            variant={activeIndex === idx ? 'gray' : 'transparent'}
            onClick={() => changeDate(date.date, activeIndex === idx)}
          >
            <p
              className={cx(css.button__text, {
                [css.button__text_active]: activeIndex === idx
              })}
            >
              {date.dayMonth}
            </p>
            <p className={css.button__text_second}>{`${
              format(nowTime, 'dd.LL') === date.dayMonth
                ? `сегодня, ${date.dayWeek}`
                : date.dayWeek
            }`}</p>
          </Button>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
