import { useState } from 'react'

import { cx } from 'class-variance-authority'

import {
  Button,
  Command,
  CommandEmpty,
  CommandItem,
  CommandList,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/shared/ui'

import { NewReservationTagPopover } from './NewReservationTagPopover'

import css from './ReservationTagsCombobox.module.css'

type ReservationTagsComboboxProps = {
  side?: 'top' | 'right' | 'bottom' | 'left'
  dataTags?: SimpleTag[]
  isLoadingDataTags?: boolean
  tags: SimpleTag[]
  setTagsHandler: (tags: SimpleTag[]) => Promise<void>
  createTagHandler?: (name: string) => Promise<void>
  isCreateTagLoading?: boolean
}

export default function ReservationTagsCombobox({
  side = 'left',
  dataTags,
  isLoadingDataTags = false,
  tags,
  setTagsHandler,
  createTagHandler,
  isCreateTagLoading
}: ReservationTagsComboboxProps) {
  const [selectedTags, setSelectedTags] = useState<SimpleTag[]>(tags)

  const [open, setOpen] = useState(false)

  const selectTagHandler = (tag: SimpleTag) => {
    const existingTag = selectedTags.find(
      (selectedTag) => selectedTag.id === tag.id
    )
    if (existingTag) {
      setSelectedTags(
        selectedTags.filter((selectedTag) => selectedTag.id !== tag.id)
      )
    } else {
      setSelectedTags((prev) => [...prev, tag])
    }
  }

  const openChangeHandler = async (open: boolean) => {
    setOpen(open)
    try {
      if (!open) {
        await setTagsHandler(selectedTags)
      }
    } catch {
      // do nothing
    }
  }

  return (
    <Popover open={open} onOpenChange={openChangeHandler} modal>
      <PopoverTrigger asChild>
        <Button variant="secondary" size="xxs" disabled={isLoadingDataTags}>
          <Icon name="plus" size={12} />
          Добавить тег
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className={css.popover}
        variant="glass"
        side={side}
        sideOffset={8}
      >
        {createTagHandler && (
          <NewReservationTagPopover
            createTagHandler={createTagHandler}
            isCreateTagLoading={isCreateTagLoading}
          />
        )}
        <Command className={css.command}>
          <CommandEmpty>Теги не найдены</CommandEmpty>
          <CommandList className={css.command__content}>
            <div className={css.command__list}>
              {dataTags?.map((tag) => {
                const isSelected = selectedTags.find(
                  (selectedTag) => selectedTag.id === tag.id
                )

                return (
                  <CommandItem
                    key={tag.id}
                    value={tag.id.toString()}
                    onSelect={() => selectTagHandler(tag)}
                    className={cx(css.command__item, [
                      {
                        [css.command__item_selected]: isSelected
                      }
                    ])}
                  >
                    {tag.name}
                  </CommandItem>
                )
              })}
            </div>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
