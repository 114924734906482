/* eslint-disable import/no-duplicates */
import { useEffect, useState } from 'react'

import { parseISO } from 'date-fns'
import { type ItemProps, Virtuoso } from 'react-virtuoso'

import { INFINITE_SCROLL_RES } from '@/app/config'
import { ReservationCard } from '@/entities/reservation'
import { selectReservationsOpenModalId } from '@/entities/reservations/model/selectors'
import { setReservationsOpenModalId } from '@/entities/reservations/model/slice'
import { useGuestVisitsQuery } from '@/entities/visits'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import {
  Error,
  InfoBox,
  Loading,
  Vaul,
  VaulContent,
  VaulTrigger
} from '@/shared/ui'
import { ReservationVaulContent } from '@/widgets/reservation'

import css from './MobileReservationsList.module.css'

type Context = {
  isContentLoading: boolean
}

function Header() {
  return <div style={{ height: 'var(--vaul-header-height)' }} />
}

export function ReservationsListFooter() {
  return (
    <div
      style={{
        height: 'calc(var(--vaul-footer-height) + 12px)'
      }}
    />
  )
}

export function ReservationsEmptyPlaceholder({
  context
}: {
  context?: Context
}) {
  if (context?.isContentLoading) {
    return (
      <div className={css.placeholder}>
        <Loading />
      </div>
    )
  }

  return (
    <InfoBox className={css.placeholder} noIcon>
      Ничего не найдено
    </InfoBox>
  )
}

function WrapperCard(props: ItemProps<Visit>) {
  return <div {...props} className={css['wrapper-card']} />
}

type ReservationCardProps = {
  reservation: Visit
  guest: Guest
  openModalId: number | null
  setOpenModalId: (value: number | null) => void
  updateReservationsList: (
    reservationId: number,
    isDelete: boolean,
    data?: Partial<Reservation | Visit>
  ) => void
}

function ReservationCardWithVaul({
  guest,
  reservation,
  openModalId,
  setOpenModalId,
  updateReservationsList
}: ReservationCardProps) {
  return (
    <Vaul
      open={openModalId === reservation.id}
      onOpenChange={(open) =>
        open ? setOpenModalId(reservation.id) : setOpenModalId(null)
      }
    >
      <VaulTrigger asChild>
        <ReservationCard
          id={reservation.id}
          guest={guest}
          personsCount={reservation.persons_count}
          startDate={parseISO(reservation.start_date)}
          mainTableNumber={
            reservation.main_table_number
              ? String(reservation.main_table_number)
              : ''
          }
          // source={reservation.source}
          // link={reservation.link}
          // tags={reservation.tags}
          status={reservation.status}
          endDate={reservation.end_date ? parseISO(reservation.end_date) : null}
          updateReservationsList={updateReservationsList}
        />
      </VaulTrigger>
      <VaulContent fullScreen>
        <ReservationVaulContent
          reservationId={reservation.id}
          updateReservationsList={updateReservationsList}
        />
      </VaulContent>
    </Vaul>
  )
}

type MobileGuestReservationsListProps = {
  guest: Guest
}

export default function MobileGuestReservationsList({
  guest
}: MobileGuestReservationsListProps) {
  const openModalId = useAppSelector(selectReservationsOpenModalId)
  const dispatch = useAppDispatch()

  const [page, setPage] = useState(1)
  const [reservations, setReservations] = useState<Visit[]>([])

  const { data, isLoading, isFetching, isError, isSuccess, refetch } =
    useGuestVisitsQuery(
      {
        guestId: guest.id,
        limit: INFINITE_SCROLL_RES,
        offset: (page - 1) * INFINITE_SCROLL_RES
      },
      {
        refetchOnMountOrArgChange: true
      }
    )

  const updateReservationsList = (
    reservationId: number,
    isDelete: boolean,
    data?: Partial<Reservation | Visit>
  ) => {
    if (isDelete) {
      setReservations((prev) =>
        prev.filter((item) => item.id !== reservationId)
      )
    } else if (data) {
      setReservations((prev) => {
        const updatedReservations = prev.map((reservation) => {
          if (reservation.id === reservationId) {
            return {
              ...reservation,
              ...(data as Partial<Visit>)
              // comments_count: data.comments_count
              //   ? Number(data.comments_count)
              //   : reservation.comments_count
            }
          }
          return reservation
        })

        return updatedReservations
      })
    }
  }

  const loadMoreRows = () => {
    if (data?.next) {
      setPage((prevPage) => prevPage + 1)
    }
  }

  const setOpenModalId = (value: number | null) => {
    dispatch(setReservationsOpenModalId(value))
  }

  useEffect(() => {
    setPage(1)
    dispatch(setReservationsOpenModalId(null))
  }, [])

  useEffect(() => {
    if (isSuccess && data && !isFetching) {
      setReservations((prev) => [...prev, ...data.results])
    }
  }, [isSuccess, data])

  const isContentLoading = (isLoading && page > 1) || (isFetching && page === 1)

  if (isError) {
    return <Error refetch={refetch} />
  }

  return (
    <Virtuoso
      className={css.reservations}
      totalCount={reservations.length}
      defaultItemHeight={150}
      data={reservations}
      endReached={loadMoreRows}
      increaseViewportBy={200}
      context={{
        isContentLoading
      }}
      components={{
        EmptyPlaceholder: ReservationsEmptyPlaceholder,
        Item: WrapperCard,
        Header,
        Footer: ReservationsListFooter
      }}
      itemContent={(_, reservation) => (
        <ReservationCardWithVaul
          guest={guest}
          reservation={reservation}
          openModalId={openModalId}
          setOpenModalId={setOpenModalId}
          updateReservationsList={updateReservationsList}
        />
      )}
    />
  )
}
